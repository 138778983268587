<template>
  <div>
    <div class="card">

      <div class="bg-blue-light p-1">
        <TitleButton
          :showBtn="false"
          :showAddNew="false"
          title="IPD Medicine Order"
          btnTitle="+ Create"
        />
      </div>

      <div class="px-2">
        <DateQuerySetter
          btnTitle="Go"
          @onClickGo="getIPDMedicineOrder"
        >
          <div class="col-12 col-sm-6 col-md-4 col-lg-4">
            <label for="colFormLabel" class="col-form-label">Search</label>
            <div class="input-group input-group-merge w-100">
                <span class="input-group-text">
                    <img :src="SearchIcon" alt="Search">
                </span>
              <input
                type="text"
                class="form-control"
                placeholder="Search/Scan Barcode - by Patient ID"
                aria-label="Search..."
                aria-describedby="chat-search"
                v-model="IPDPatientId"
                @keyup="getIPDMedicineOrder"
              />

            </div>
          </div>

          <div class="col-6 col-sm-2 col-md-2 col-lg-2">
            <label for="colFormLabel" class="col-form-label">Admission no</label>
            <div class="input-group input-group-merge w-100">

              <input
                type="text"
                class="form-control"
                placeholder="Admission no"
                aria-label="Search..."
                aria-describedby="chat-search"
                v-model="admissionNumber"
              />

            </div>
          </div>

          <div class="col-12 col-sm-6 col-md-4 col-lg-4">
            <label for="colFormLabel" class="col-form-label">Select Consultant </label>
            <AsyncSelect
              placeholder="Select consultant"
              class="w-100"
              v-model="selectedIPDDoctor"
              :api-service="fetchServiceList"
              :additional-query="{ resource_type: 'human_resources' }"
            />
          </div>

        </DateQuerySetter>
      </div>

      <div class="col-12 px-2 mt-2">
        <ListTable :tableItems="ipdTableItems"/>
      </div>
    </div>

    <div class="card">

      <div class="bg-blue-light p-1">
        <TitleButton
          :showBtn="false"
          :showAddNew="false"
          title="OPD Medicine Order"
          btnTitle="+ Create"
        />
      </div>

      <div class="px-2">
        <DateQuerySetter
          btnTitle="Go"
          @onClickGo="getOPDMedicineOrder"
        >
          <div class="col-12 col-sm-6 col-md-4 col-lg-4">
            <label for="colFormLabel" class="col-form-label">Search</label>
            <div class="input-group input-group-merge w-100">
              <span class="input-group-text">
                <img :src="SearchIcon" alt="Search">
              </span>
              <input
                type="text" class="form-control"
                placeholder="Search/Scan Barcode - by Patient ID" aria-label="Search..."
                aria-describedby="chat-search"
                v-model="OPDPatientId"
              />

            </div>
          </div>

          <div class="col-12 col-sm-6 col-md-4 col-lg-4">
            <label for="colFormLabel" class="col-form-label">Select Consultant</label>
            <AsyncSelect
              placeholder="Select consultant"
              class="w-100"
              v-model="selectedOPDDoctor"
              :api-service="fetchServiceList"
              :additional-query="{ resource_type: 'human_resources' }"
            />
          </div>

        </DateQuerySetter>
      </div>

      <div class="col-12 px-2 mt-2">
        <ListTable prescription-type="OPD" :tableItems="opdTableItems" :offset="offset" state-name="secondPaginationData" />
      </div>
    </div>

    <GlobalLoader/>
  </div>
</template>

<script setup>
import TitleButton from '@/components/atom/TitleButton';
import ListTable from '@/components/molecule/company/hospital/pharmacy-billing/PharmacyBillingTable.vue';
import {useRoute} from 'vue-router';
import DateQuerySetter from '@/components/atom/DateQuerySetter.vue';
import {computed, onMounted, ref} from "vue";
import GlobalLoader from "@/components/atom/GlobalLoader.vue";
import {useStore} from "vuex";
import handleHospitalBilling from "@/services/modules/hospital/billing";
import AsyncSelect from "@/components/molecule/input-field/AsyncSelect.vue";
import handleHospital from "@/services/modules/hospital";
import filterNullFromObject from "@/services/utils/filterNullFromObject";
import SearchIcon from "@/assets/search-icon.svg"

const $route = useRoute();
const $store = useStore();
const { fetchDoctorOrders } = handleHospitalBilling();
const { fetchServiceList } = handleHospital()

const companyId = $route.params.companyId;

const selectedIPDDoctor = ref(null);
const selectedOPDDoctor = ref(null);
const OPDPatientId = ref(null);
const IPDPatientId = ref(null);
const admissionNumber = ref(null);
const ipdTableItems = ref([]);
const offset = ref(10);
const page = ref(1);
const opdTableItems = ref([]);

// Computed
const start = computed(() => $route.query.start);
const end = computed(() => $route.query.end);

const getQuery = (isOpd = true) => {
  const queryObject = {
    company_id: companyId,
    start_date: start.value,
    end_date: end.value,
    offset: offset.value,
    page: page.value,
    has_medicine: true,
    prescription_type: isOpd ? 'opd_prescription' : null,
    skip_prescription_type: !isOpd ? 'opd_prescription' : null,
    patient_id: isOpd ? OPDPatientId.value : IPDPatientId.value,
    opd_doctor_id: selectedOPDDoctor.value ? selectedOPDDoctor.value.id : null,
    ipd_doctor_id: selectedIPDDoctor.value ? selectedIPDDoctor.value.id : null,
    admission_no: admissionNumber.value,
    is_pharmacy_invoice: 1
  }
  return new URLSearchParams(filterNullFromObject(queryObject)).toString();
};

const getOPDMedicineOrder = async () => {
  $store.state.loading = true;
  try {
    const res = await fetchDoctorOrders("?" + getQuery());
    if (!res.status) {
      opdTableItems.value = []
      return $store.commit("resetPagination", 'secondPaginationData')
    }
    opdTableItems.value = res.data.data;
    if (opdTableItems.value.length > 0) {
      setPagination(res.data, 'secondPaginationData')
    }
  } catch (err) {
    if (err.response) {
      showError(err.response.message)
    }
  } finally {
    $store.state.loading = false;
  }
}

const getIPDMedicineOrder = async () => {
  $store.state.loading = true;
  try {
    const res = await fetchDoctorOrders("?" + getQuery(false));
    if (!res.status) {
      ipdTableItems.value = []
      return $store.commit("resetPagination")
    }
    ipdTableItems.value = res.data.data;
    if (ipdTableItems.value.length > 0) {
      setPagination(res.data)
    }
  } catch (err) {
    if (err.response) {
      showError(err.response.message)
    }
  } finally {
    $store.state.loading = false;
  }
}


const setPagination = (data, stateName = 'paginationData') => {
  $store.commit('setPaginationData', data, {
    records: data.total,
    perPage: data.per_page,
    currentPage: data.current_page,
  }, stateName)
};

onMounted(() => {
  getOPDMedicineOrder();
  getIPDMedicineOrder();
})
</script>

<style>

</style>